.pricing-header {
	margin-bottom: 48px;
}

.pricing-tables-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -12px;
    margin-left: -12px;

    &:first-child {
        margin-top: -12px;
    }

    &:last-child {
        margin-bottom: -12px;
    }
}

.pricing-table {
	position: relative;
    padding: 12px;
    width: 368px;
    max-width: 368px;
    flex-grow: 1;

	&::before {
		content: '';
		position: absolute;
		left: 50%;
		width: 200%;
		max-width: 200%;
		height: 435px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		bottom: 18.8%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		background-image: url('../../images/pricing-illustration.svg');
	}
}

.pricing-table-header,
.pricing-table-features-title,
.pricing-table-features li {
	border-bottom: 1px solid rgba(color(typography, 2), .24);
}

.pricing-table-inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background: color(bg, 4);
    padding: 24px;
    height: 100%;

    > * {
		position: relative; /* To display all elements above the box with shadow */
        width: 100%;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include shadow;
    }
}

.pricing-table-price {
	@if ( get-font-family(heading) != get-font-family(base) ) {
		@include font-family(heading);
	}
}

.pricing-table-price-currency {
	color: color(typography, 2);
}

.pricing-table-features-title {
	color: color(typography, 1);
	@include font-weight(bold);
}

.pricing-table-features {

    li {
        display: flex;
        align-items: center;
        padding: 14px 0;

        &::before {
			content: '';
		    width: 16px;
		    height: 12px;
		    margin-right: 16px;
		    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUuNiA2LjRMMS42IDQgMCA1LjYgNS42IDEyIDE2IDEuNiAxNC40IDB6IiBmaWxsPSIjMDJDQkIxIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
		    background-repeat: no-repeat;
		}
    }
}

.pricing-table-cta {
    align-self: flex-end;
}

@include media( '>medium' ) {

	.pricing {

		.section-paragraph {
	        padding-left: 90px;
	        padding-right: 90px;
	    }
	}

	.pricing-header {
		margin-bottom: 52px;
	}
}
