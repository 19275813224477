.features-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-right: -32px;
    margin-left: -32px;

    &:first-of-type {
        margin-top: -16px;
    }

    &:last-of-type {
        margin-bottom: -16px;
    }
}

.feature {
    padding: 16px 32px;
    width: 380px;
    max-width: 380px;
    flex-grow: 1;
}

.feature-inner {
    height: 100%;
}

.feature-icon {
	display: flex;
    justify-content: center;
}

@include media( '>medium' ) {

    .features-wrap {

        &:first-of-type {
            margin-top: -24px;
        }

        &:last-of-type {
            margin-bottom: -24px;
        }
    }

    .feature {
        padding: 32px 32px;
    }
}
