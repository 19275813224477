.cta {
	text-align: center;

	.section-inner {
		padding: 48px 16px;
	}

	.section-title {
		margin-bottom: 40px;
	}
}

.cta-inner {
	position: relative;
	background: color(bg, 1);
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		right: 98px;
		top: -117px;
		width: 160px;
		height: 187px;
		background-image: url('../../images/cta-illustration.svg');
		background-repeat: no-repeat;
	}

	> * {
		position: relative; /* To display elements above hero illustrations */
	}
}

@include media( '>medium') {

	.cta {
		text-align: left;

		.section-inner {
			padding: 64px 32px;
		}

		.section-title {
			margin-bottom: 0;
			padding-right: 24px;
		}
	}

	.cta-inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
