.hero {
	text-align: center;
    padding-top: 18px;
	padding-bottom: 10px;
}

.hero-copy {
    position: relative; /* To display elements above hero illustrations */
	text-align: center;
	z-index: 1;
}

.hero-cta {
	margin-bottom: 40px;
}

.hero-figure {
	position: relative;

	svg {
		width: 100%;
		height: auto;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		background-repeat: no-repeat;
		background-size: 100%;

		.has-animations & {
			opacity: 0;
			transition: opacity 2s ease;

			.anime-ready & {
				opacity: 1;
			}
		}
	}

	&::before {
		top: -57.8%;
		left: -1.3%;
		width: 152.84%;
		height: 178.78%;
		background-image: url('../../images/hero-back-illustration.svg');
	}

	// &::after {
	// 	top: -35.6%;
	// 	left: 99.6%;
	// 	width: 57.2%;
	// 	height: 87.88%;
	// 	background-image: url('../../images/hero-top-illustration.svg');
	// }
}

.hero-figure-box {
	position: absolute;
	top: 0;
	will-change: transform;
}

.hero-figure-box-01,
.hero-figure-box-02,
.hero-figure-box-03,
.hero-figure-box-04,
.hero-figure-box-08,
.hero-figure-box-09 {
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transform-origin: 100% 100%;
	}
}

.hero-figure-box-01 {
	left: 103.2%;
	top: 41.9%;
	width: 28.03%;
	height: 37.37%;
	background: linear-gradient(to left top, #00BFFB, rgba(#00BFFB, 0));
	transform: rotateZ(45deg);

	&::before {
		background: linear-gradient(to left, color(bg, 1) 0%, rgba(color(bg, 1), 0) 60%);
		transform: rotateZ(45deg) scale(1.5);
	}
}

.hero-figure-box-02 {
	left: 61.3%;
	top: 64.1%;
	width: 37.87%;
	height: 50.50%;
	background: linear-gradient(to left top, color(primary, 1), rgba(color(primary, 1), 0));
	transform: rotateZ(-45deg);

	&::before {
		background: linear-gradient(to top, color(bg, 1) 0%, rgba(color(bg, 1), 0) 60%);
		transform: rotateZ(-45deg) scale(1.5);
	}
}

.hero-figure-box-03 {
	left: 87.7%;
	top: -56.8%;
	width: 56.81%;
	height: 75.75%;
	background: linear-gradient(to left top, #00BFFB, rgba(#00BFFB, 0));

	&::before {
		background: linear-gradient(to left, color(bg, 1) 0%, rgba(color(bg, 1), 0) 60%);
		transform: rotateZ(45deg) scale(1.5);
	}
}

.hero-figure-box-04 {
	left: 54.9%;
	top: -8%;
	width: 45.45%;
	height: 60.60%;
	background: linear-gradient(to left top, color(primary, 1), rgba(color(primary, 1), 0));
	transform: rotateZ(-135deg);

	&::before {
		background: linear-gradient(to top, rgba(color(typography, 1), .24) 0%, rgba(color(typography, 1), 0) 60%);
		transform: rotateZ(-45deg) scale(1.5);
	}
}

.hero-figure-box-05,
.hero-figure-box-06,
.hero-figure-box-07 {
	background-color: color(bg, 3);
	box-shadow: -20px 32px 64px rgba(#000, .25);
}

.hero-figure-box-05 {
	left: 17.4%;
	top: 13.3%;
	width: 64%;
	height: 73.7%;
	transform: perspective(500px) rotateY(-15deg) rotateX(8deg) rotateZ(-1deg);
}

.hero-figure-box-06 {
	left: 65.5%;
	top: 6.3%;
	width: 30.3%;
	height: 40.4%;
	transform: rotateZ(20deg);
}

.hero-figure-box-07 {
	left: 1.9%;
	top: 42.4%;
	width: 12.12%;
	height: 16.16%;
	transform: rotateZ(20deg);
}

.hero-figure-box-08 {
	left: 27.1%;
	top: 81.6%;
	width: 19.51%;
	height: 26.01%;
	background: color(primary, 1);
	transform: rotateZ(-22deg);

	&::before {
		background: linear-gradient(to left, rgba(color(typography, 1), 0) 0%, rgba(color(typography, 1), .48) 100%);
		transform: rotateZ(45deg) scale(1.5);
	}
}

.hero-figure-box-09 {
	left: 42.6%;
	top: -17.9%;
	width: 6.63%;
	height: 8.83%;
	background: #00BFFB;
	transform: rotateZ(-52deg);

	&::before {
		background: linear-gradient(to left, rgba(color(typography, 1), 0) 0%, rgba(color(typography, 1), .64) 100%);
		transform: rotateZ(45deg) scale(1.5);
	}
}

.hero-figure-box-10 {
	left: -3.8%;
	top: 4.3%;
	width: 3.03%;
	height: 4.04%;
	background: rgba(#00BFFB, .32);
	transform: rotateZ(-50deg);
}

@include media( '<=medium' ) {

	.hero-cta {
		max-width: 280px;
		margin-left: auto;
		margin-right: auto;

		.button {
			display: flex;

			+ .button {
				margin-top: 16px;
			}
		}
	}

	.hero-figure::after,
	.hero-figure-box-03,
	.hero-figure-box-04,
	.hero-figure-box-09 {
		display: none;
	}
}

@include media( '>medium' ) {

    .hero {
		text-align: left;
        padding-top: 18px;
        padding-bottom: 10px;
    }

	.hero-inner {
		/* Split hero in two half */
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 70vh;
	}
	.slogan {
		padding-top: 64px;
		padding-bottom: 64px;
		align-items: center;
		margin: auto;
		text-align: center;
	}
    .hero-copy {
		padding-right: 64px;
		padding-left: 64px;
        min-width: 552px;
        width: 552px;
    }

	.hero-cta {
		margin: 0;

		.button {
			min-width: 170px;

			&:first-child {
				margin-right: 16px;
			}
		}
	}

	.hero-figure {

		svg {
			width: auto;
		}
	}
}
