.site-footer {
    @include font-size(8, mobile, true, true, true);
    @if ( get-font-size(8, desktop) != get-font-size(8, mobile) ) {
        @include media( '>medium' ) {
            @include font-size(8, desktop, true, true, true);
        }
    }

    a {
        @include anchor-aspect(footer);
    }
}

.site-footer-inner {
    position: relative; /* To display all elements above the background color */
    display: flex;
    flex-wrap: wrap;
    padding-top: 48px;
    padding-bottom: 48px;
}

.footer-brand,
.footer-links,
.footer-social-links,
.footer-copyright {
    flex: none;
    width: 100%;
    display: inline-flex;
    justify-content: center;
}

.footer-brand,
.footer-links,
.footer-social-links {
    margin-bottom: 24px;
}

.footer-social-links {

    li {
        display: inline-flex;

		+ li {
			margin-left: 16px;
		}

        a {
            padding: 8px;
        }
    }
}

.footer-links {

    li {

        + li {
            margin-left: 24px;
        }
    }
}

@include media( '>medium' ) {

	.site-footer {
		margin-top: 20px;
        background-color: color(bg, 5);
	}

    .site-footer-inner {
        justify-content: space-between;
		padding-top: 10px;
		padding-bottom: 10px;
    }

    .footer-brand,
    .footer-links,
    .footer-social-links,
    .footer-copyright {
        flex: 50%;
    }

    .footer-brand,
    .footer-copyright {
        justify-content: flex-start;
    }

    .footer-links,
    .footer-social-links {
        justify-content: flex-end;
    }

    .footer-links {
        order: 1;
        margin-bottom: 0;
    }
}
